// ajax 配合JQ3 引入設置
$(document).ready(function () {
  $.ajax({
    url: "ajax/_header.html",
    method: "GET",
    dataType: "html"
  }).done(
    function (data) {
      $("#header").html(data);
      toggleMobileMenu();
      goToAnchor();
      goTop();
    }
  );
  $("#footer").load("ajax/_footer.html");
  $("#pills-north").load("ajax/_table_north.html");
  $("#pills-center").load("ajax/_table_center.html");
  $("#pills-south").load("ajax/_table_south.html");
  $("#pills-east").load("ajax/_table_east.html");
});
//go top
function goTopShow() {
  // console.log($(window).scrollTop());
  if ($(window).scrollTop() > 400) {
    $(".l-header-goTop").addClass('js-show');
  } else {
    $(".l-header-goTop").removeClass('js-show');
  }
}
function goTop() {
  //置頂按鈕的捲動速度
  $(".l-header-goTop").click(function (event) {
    console.log('ccc');
    event.preventDefault;
    $('html,body').animate({
      scrollTop: 0
    }, 1000);
  });
}
function goToAnchor() {
  $('.js-goToAnchor').click(function (e) {
    e.preventDefault();
    var target = $(this).attr('href');
    var targetPos = $(target).offset().top;
    $('html,body').animate({
      scrollTop: targetPos - 50
    }, 1000);
    var trigger02 = document.querySelector("#hamburger");
    var target02 = document.querySelector("#menu");
    trigger02.classList.remove("js-menuOpened");
    target02.classList.remove("js-menuOpened");
    document.documentElement.classList.remove("js-menuOpened");
  });
}
function toggleMobileMenu(mediaQuery) {
  var trigger = document.querySelector("#hamburger");
  var target = document.querySelector("#menu");

  trigger.addEventListener("click", function () {
    this.classList.toggle("js-menuOpened");
    target.classList.toggle("js-menuOpened");
    document.documentElement.classList.toggle("js-menuOpened");
  });

  window.addEventListener("resize", function () {
    if (window.innerWidth >= mediaQuery) {
      trigger.classList.remove("js-menuOpened");
      target.classList.remove("js-menuOpened");
      document.documentElement.classList.remove("js-menuOpened");
    }
  });
}

function headerFunction() {
  var breakpoint = 768;
  toggleMobileMenu(breakpoint);
}

function toolsListener() {
  window.addEventListener("keydown", function (e) {
    if (e.keyCode === 9) {
      document.body.classList.remove("js-useMouse");
      document.body.classList.add("js-useKeyboard");
    }
  });
  window.addEventListener("mousedown", function (e) {
    document.body.classList.remove("js-useKeyboard");
    document.body.classList.add("js-useMouse");
  });
}

function readMore() {
  el = document.querySelector(".p-notice-arrow");
  if (el) {
    el.addEventListener("click", function () {
      event.preventDefault();
      this.classList.toggle("js-active");
      document.querySelector(".p-notice-content").classList.toggle("js-active");
    })
  }
}
//呼叫function-網頁載入完成後
$(document).ready(function () {
  // toolsListener();
  AOS.init({
    once: true
  });
  readMore();
});
//呼叫function-視窗大小變更
$(window).resize(function () { });
//呼叫function-捲動
$(window).scroll(function () {
  goTopShow();
});