// import file
//北區 中區 南區
import north_stores from './data/north_stores.js';
import center_stores from './data/center_stores.js';
import south_stores from './data/south_stores.js';
import east_stores from './data/east_stores.js';

import w1_winners from './winner/w1_winners.js';
import w2_winners from './winner/w2_winners.js';
import w3_winners from './winner/w3_winners.js';
import w4_winners from './winner/w4_winners.js';
import w5_winners from './winner/w5_winners.js';
import w6_winners from './winner/w6_winners.js';
import w7_winners from './winner/w7_winners.js';
import w8_winners from './winner/w8_winners.js';
import w9_winners from './winner/w9_winners.js';
import w10_winners from './winner/w10_winners.js';
import w11_winners from './winner/w11_winners.js';
import w12_winners from './winner/w12_winners.js';
import w13_winners from './winner/w13_winners.js';
import w14_winners from './winner/w14_winners.js';


// 每週import一個js  
// console.log(north_stores)
// console.log(center_stores)
// console.log(south_stores)
// console.log(east_stores)
//{ id: 'east', name: '東區', stores: east_stores }

const vue = new Vue({
    el: '#app',
    data: {
        activeItem: 'north',
        sections: [
            { id: 'north', name: '北區', stores: north_stores },
            { id: 'center', name: '中區', stores: center_stores },
            { id: 'south', name: '南區', stores: south_stores },
            { id: 'east', name: '東區', stores: east_stores }, 
               
        ],
        

    },
    methods: {
        isActive(menuItem) {
            return this.activeItem === menuItem
        },
        setActive(menuItem) {
            this.activeItem = menuItem
        }
    }
});

const vue2 = new Vue({
    el: '#appwin',
    data: {
        winners:[
            { id: 1, title: w1_winners.title, winners: w1_winners.datas},
            { id: 2, title: w2_winners.title, winners: w2_winners.datas},
            { id: 3, title: w3_winners.title, winners: w3_winners.datas},
            { id: 4, title: w4_winners.title, winners: w4_winners.datas},
            { id: 5, title: w5_winners.title, winners: w5_winners.datas},
            { id: 6, title: w6_winners.title, winners: w6_winners.datas},
            { id: 7, title: w7_winners.title, winners: w7_winners.datas},
            { id: 8, title: w8_winners.title, winners: w8_winners.datas},
            { id: 9, title: w9_winners.title, winners: w9_winners.datas},
            { id: 10, title: w10_winners.title, winners: w10_winners.datas},
            { id: 11, title: w11_winners.title, winners: w11_winners.datas},
            { id: 12, title: w12_winners.title, winners: w12_winners.datas},
            { id: 13, title: w13_winners.title, winners: w13_winners.datas},
            { id: 14, title: w14_winners.title, winners: w14_winners.datas}

        ],
        //使用時，複製一行物件：修改id 與 w1編號就可以
        // 每週放入一個obj  
        openItem:'得獎名單預計於11月3日公布，敬請期待！'
        // 每週放入一個obj    
        // winners: [
        // { id: 1, title: w1_winners.title, winners: w1_winners.datas },
        // { id: 2, title: w2_winners.title, winners: w2_winners.datas },
        // { id: 3, title: w3_winners.title, winners: w3_winners.datas },
        // ]
    }
});
